import Vue from 'vue'
import VueRouter from 'vue-router'

import home from "@/views/index"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "首页",
    component: home
  }
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
