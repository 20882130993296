import Vue from 'vue'
import App from './App.vue'
import router from './router'


// elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import animated from 'animate.css'
Vue.use(animated)

// api请求
import {getApiConfigParams, getApiConfig, postApiConfig, deleteApiConfig, deleteApiConfigParams, putApiConfig} from "@/api/apiConfig"
Vue.prototype.getApiConfigParams = getApiConfigParams;
Vue.prototype.getApiConfig = getApiConfig;
Vue.prototype.postApiConfig = postApiConfig;
Vue.prototype.deleteApiConfig = deleteApiConfig;
Vue.prototype.deleteApiConfigParams = deleteApiConfigParams;
Vue.prototype.putApiConfig = putApiConfig;


Vue.config.productionTip = false;

// 跳转后返回顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
