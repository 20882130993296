<script>
export default {
  name: "index"
}
</script>

<template>
  <div class="header-page">
    <div class="header-title animate__bounceIn">
      <div class="title-item">
        <span>2024年</span>
      </div>
      <div class="title-item">
        <span>
           阳历生日(年月日)
        </span>
      </div>
    </div>
    <div class="header-content">
      <span>看看你的答案</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
@font-face {
  font-family: AlimamaDaoLiTi;
  src: url('../../assets/font/AlimamaDaoLiTi.ttf');
}

.header-page{
  width: 13.65rem  /* 1365/100 */;
  height: 4rem  /* 400/100 */;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: relative;

  .header-title{
    width: 100%;
    //height: 1.98rem  /* 198/100 */;
    letter-spacing: 0.15rem  /* 15/100 */;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #FF0000;
    font-family: AlimamaDaoLiTi;

    .title-item{
      font-weight: 500;
      text-align: center;

      &:nth-child(1){
        font-size: 1rem  /* 100/100 */;
      }
      &:nth-child(2){
        font-size: 1rem  /* 100/100 */;
      }
    }
  }
  .header-content{
    margin-top: 0.64rem  /* 64/100 */;
    width: 8.13rem  /* 813/100 */;
    height: 0.56rem  /* 56/100 */;
    color: #FF0000;
    font-size: 0.5rem  /* 50/100 */;
    font-weight: 400;
    text-align: center;
    font-family: AlimamaDaoLiTi;
  }

  .burner{
    width: 1.7rem  /* 170/100 */;
    height: 1.92rem  /* 192/100 */;
    position: relative;

    .candle-smoke{
      width: 0.3rem  /* 30/100 */;
      height: 1.92rem  /* 192/100 */;
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: bottom center;
      filter: blur(3px);

      span {
        position: absolute;
        bottom: -0.09rem  /* -9/100 */; left: -0.09rem  /* -9/100 */;
        width: 0.18rem  /* 18/100 */; height: 0.18rem  /* 18/100 */;
        background-image: radial-gradient(closest-side circle, rgba(255,255,255,.75), rgba(255,255,255,.6), rgba(255,255,255,.4), rgba(255,255,255,0));
        border-radius: 18px;
      }
      .s0 {
        animation: smokeL 10s 0s infinite;
      }
      .s1 {
        animation: smokeR 10s 1s infinite;
      }
      .s2 {
        animation: smokeL 10s 2s infinite;
      }
      .s3 {
        animation: smokeR 10s 3s infinite;
      }
      .s4 {
        animation: smokeL 10s 4s infinite;
      }
      .s5 {
        animation: smokeR 10s 5s infinite;
      }
      .s6 {
        animation: smokeL 10s 6s infinite;
      }
      .s7 {
        animation: smokeR 10s 7s infinite;
      }
      .s8 {
        animation: smokeL 10s 8s infinite;
      }
      .s9 {
        animation: smokeR 10s 9s infinite;
      }

    }

    /* 烟雾动画 */
    @keyframes smokeL {
      0%   { opacity: 0; transform: scale(1) translate(0, 0); }
      10%  { opacity: .8; transform: scale(1) translate(0, -7px); }
      100% { opacity: 0; transform: scale(5) translate(-4px, -26px); }
    }

    @keyframes smokeR {
      0%   { opacity: 0; transform: scale(1) translate(0, 0); }
      10%  { opacity: .8; transform: scale(1) translate(0, -7px); }
      100% { opacity: 0; transform: scale(5) translate(4px, -26px); }
    }

    .location1{
      transform: translate(-210%, -115%) scale(0.7);
    }
    .location2{
      transform: translate(-140%, -110%) scale(0.7);
    }
    .location3{
      transform: translate(-70%, -105%) scale(0.7);
    }
    .location4{
      transform: translate(-5%, -105%) scale(0.7);
    }
    .location5{
      transform: translate(60%, -110%) scale(0.7);
    }
    .location6{
      transform: translate(125%, -115%) scale(0.7);
    }
    .location7{
      transform: translate(190%, -115%) scale(0.7);
    }
  }

  .locationBurner1{
    position: absolute;
    top: 0;
    left: 0;
  }

  .locationBurner2{
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
