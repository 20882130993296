<template>
  <div class="home-page" :style="{padding: successShow ? '1.64rem  /* 164/100 */ 0 0' : '0.66rem  /* 66/100 */ 0 0'}">
    <div class="home-content">
      <!-- 头部标题 -->
      <headerBox v-if="successShow"/>
      <!-- 生日 -->
      <birthdayBox @success="successData" v-if="successShow"/>
      <!-- 测试结果 -->
      <resultBox :resultData="resultData" v-if="false"/>
      <!-- 命数解释 -->
      <fateBox ref="fateScrollBox" :fateData="fateData" @step="getStep"/>
    </div>
    <el-backtop target=".home-page" :bottom="100" :right="100">
      <div style="{height: 100%;width: 100%;border-radius: 50%;background-color: rgba(0,0,0,.8);box-shadow: 0 0 4px #978681;text-align: center;line-height: 40px;}">
        <img src="../assets/img/icon_6.png" style="width: 10px;height: 18px;display: inline-block;">
      </div>
    </el-backtop>
  </div>
</template>

<script>
import headerBox from "../components/header/index.vue";
import birthdayBox from "../components/birthday/index.vue";
import resultBox from "../components/result/index.vue";
import fateBox from "../components/fate/index.vue";
export default {
  name: "index",
  components:{
    headerBox,
    birthdayBox,
    resultBox,
    fateBox,
  },
  data(){
    return{
      resultData:{},
      fateData:{},
      successShow: true,
    }
  },
  mounted() {
  },
  methods:{
    successData(data){
      console.log("success",data)
      this.resultData = Object.assign({},data);
      this.fateData = Object.assign({},data);
      this.successShow = false;
    },
    getStep(value){
      this.successShow = value;
      this.resultData = {};
      this.fateData = {};
    }
  }
}
</script>

<style lang="scss">
.home-page{
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  //background: url("../assets/img/bg.png") center no-repeat;
  //background: url("../assets/img/bg2.jpg") center no-repeat;
  background: url("../assets/img/bg4.jpg") center no-repeat;
  background-size:cover;
  overflow-y: auto;

  .home-content{
    width: 16.36rem  /* 1636/100 */;
    margin: 0 auto;
  }
}
</style>
