<script>
import dayjs from 'dayjs';

export default {
  name: "index",
  data() {
    return {
      // 日历
      calendarList: [
        {name: '阳历',value: 0,},
        {name: '阴历',value: 1,}
      ],
      calendarStr: '',
      calendarIndex: 0,
      calendarShow: false,
      // 年份
      yearList: [],
      yearIndex: '',
      // 月份
      monthList: [],
      monthIndex: '',
      // 日期
      dayList: [],
      dayIndex: '',
      // 加载动画
      loadingShow:false,
      // 是否显示弹窗
      messageShow:false,
      messageStr: '',
      messageCode: '',
      // 输入框
      inputFocus: false,
      inputBirthday: '',
      formattedBirthday: '',
      lunar: 0, // 农历:1,公历:0
    }
  },
  mounted() {
    this.calendarStr = this.calendarList[0].name
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    this.yearList = this.sortDataByFallout(this.generateYears(1900, currentYear));
    this.monthList = this.getCurrentYearAndPastMonths(currentYear - 1);
    this.dayList = this.generateAllDaysOfMonth(currentYear - 1,currentMonth);
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    // 排序方法
    sortDataByFallout(data) {
      return data.sort((a, b) => b.name - a.name);
    },
    // 获取范围的年份
    generateYears(startYear, endYear) {
      // 创建一个空数组来存储年份
      const yearsArray = [];
      let i = 1;
      // 遍历结束年份到开始年份之间的每个年份，并将其添加到数组中（倒序）
      for (let year = endYear; year >= startYear; year--) {
        yearsArray.push({
          name: year,
          value: i++,
        });
      }
      return yearsArray;
    },
    // 获取月份
    getCurrentYearAndPastMonths(year) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      const months = [];
      let i = 1;
      if (year === currentYear) {
        for (let month = 0; month <= currentDate.getMonth(); month++) {
          months.push({
            name: month+1,
            value: i++,
          });
        }
      }else{
        for (let month = 0; month < 12; month++) {
          months.push({
            name: month+1,
            value: i++,
          });
        }
      }
      return months;
    },
    // 获取日期
    generateAllDaysOfMonth(year, monthIndex) {
      const startDate = new Date(year, monthIndex, 1);
      const endDate = new Date(year, monthIndex + 1, 0); // 下一个月的前一天是本月最后一天
      let i = 1;
      const allDates = [];

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      if (year === currentYear && monthIndex === currentDate.getMonth()) {
        for (let day = 1; day <= currentDate.getDate(); day++) {
          allDates.push({
            name: day,
            value: i++,
          });
        }
      }else{
        for (let currDate = startDate; currDate <= endDate; currDate.setDate(currDate.getDate() + 1)) {
          allDates.push({
            name: new Date(currDate).getDate(),
            value: i++,
          });
        }
      }
      return allDates;
    },
    // 选择年
    changeYear(index){
      if(!index) return false;
      const singleYearString = this.yearList[index-1].name;
      this.monthList = this.getCurrentYearAndPastMonths(singleYearString);
      this.dayList = this.generateAllDaysOfMonth(singleYearString,this.monthIndex-1);
    },
    // 选择月
    changeMonth(index){
      if(!index) return false;
      const singleYearString = this.yearList[this.yearIndex-1].name;
      this.monthList = this.getCurrentYearAndPastMonths(singleYearString);
      this.dayList = this.generateAllDaysOfMonth(singleYearString,index-1);
    },
    // 测试
    async getList() {
      if (!this.validateBirthday()) {
        return;
      }

      try {
        this.showLoading();
        // 选择框
        // const birthday = this.formatBirthday.call(this, this.yearIndex, this.monthIndex, this.dayIndex);
        // const result = await this.getApiConfigParams('/api/index/lifeAnalysis', { birthday });

        // 输入框
        const data = {
          birthday: this.formattedBirthday,
          lunar: this.lunar,
        };
        const result = await this.getApiConfigParams('/api/index/lifeAnalysis', data);

        if (this.isSuccessResponse(result)) {
          this.messageCode = 200;
          this.messageStr = '请求成功';
          this.$emit('success', result.data);
        } else {
          this.handleError('请求失败，稍后再试');
          this.$emit('success', []);
        }
      } catch (error) {
        this.handleError('请求失败');
      } finally {
        this.hideLoading();
        this.clearMessageAfterDelay();
      }
    },
    validateBirthday() {
      // 选择框
      // if (!this.yearIndex || !this.monthIndex || !this.dayIndex) {
      //   this.showMessage('请输入完整的生日日期');
      //   return false;
      // }
      // 输入框
      if (!this.formattedBirthday) {
        this.showMessage('请输入完整的生日日期');
        return false;
      }
      return true;
    },
    showLoading() {
      this.loadingShow = true;
    },
    hideLoading() {
      this.loadingShow = false;
    },
    showMessage(message) {
      this.messageShow = true;
      this.messageStr = message;
      this.clearMessageAfterDelay();
    },
    clearMessageAfterDelay() {
      setTimeout(() => {
        this.messageShow = false;
      }, 3000);
    },
    isSuccessResponse(response) {
      return response?.code === 200 && 'code' in response;
    },
    handleError(message) {
      console.log(message);
      this.messageCode = 500;
      this.messageStr = message;
      this.showMessage(message);
      this.clearMessageAfterDelay();
    },
    // 封装一个格式化日期的方法
    formatBirthday(yearIndex, monthIndex, dayIndex) {
      const year = this.yearList[yearIndex - 1].name;
      const month = ("0" + (this.monthList[monthIndex - 1].name)).slice(-2);
      const day = ("0" + (this.dayList[dayIndex - 1].name)).slice(-2);

      return `${year}-${month}-${day}`;
    },
    formatBirthdayInput() {
      const regex = /^[0-9]+$/; // 只匹配非负整数
      if(regex.test(this.inputBirthday)){
        if(this.inputBirthday.length == 8){
          if(this.isValidYYYYMMDD(this.inputBirthday)){
            const inputDateString = this.inputBirthday;
            const parsedDate = dayjs(inputDateString, 'YYYYMMDD');
            if(this.validateDate(parsedDate.format('YYYY-MM-DD'))){
              console.log("true")
              console.log("formattedBirthday",this.formattedBirthday)
              this.formattedBirthday = parsedDate.format('YYYY-MM-DD');
            }else{
              this.showMessage('您输入的日期超出了当前日期。')
              this.formattedBirthday = '';
            }
          }
        }
      }else{
        console.log("false")
        this.inputBirthday = '';
        // this.showMessage('输入的日期格式不正确，请重新输入。')
      }
    },
    isValidYYYYMMDD(dateStr) {
      const regex = /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
      let str = ''
      if(regex.test(dateStr)){
        str = true
      }else{
        str = false;
        console.log(666,'false')
        this.showMessage('输入的日期格式不正确，请重新输入。')
      }
      return str;
    },
    validateDate(inputDate) {
      const inputParts = inputDate.split('-'); // 假设输入的日期格式为'YYYY-MM-DD'
      const inputYear = parseInt(inputParts[0], 10);
      const inputMonth = parseInt(inputParts[1], 10) - 1; // JavaScript的月份是从0开始的
      const inputDay = parseInt(inputParts[2], 10);

      const today = new Date();
      // const todayYear = today.getFullYear();
      // const todayMonth = today.getMonth();
      // const todayDay = today.getDate();

      const inputDateTime = new Date(inputYear, inputMonth, inputDay);

      return inputDateTime <= today;
    },
    onFocus(index){
      switch (index){
        case 1:
          this.calendarShow = true;
          break;
        case 2:
          this.inputFocus = true;
          break;
      }
    },
    onBlur(index){
      switch (index){
        case 1:
          this.calendarShow = false;
          break;
        case 2:
          this.inputFocus = false;
          break;
      }
    },
    // 选择日历
    calendarItemClick(item){
      console.log('选择日历',item)
      this.calendarIndex = item.value;
      this.calendarStr = item.name;
      this.lunar = item.value;
      this.calendarShow = false;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target) && this.calendarShow) {
        this.calendarShow = false;
      }
    },
  }
}
</script>

<template>
  <div>
    <div class="birthday-page">
      <div class="birthday-item">
        <span>请输入生日</span>
      </div>
      <div class="birthday-item">
<!--        <el-select v-model="lunar" filterable placeholder="请选择" clearable style="width: 130px;">-->
<!--          <el-option v-for="item in calendarList" :key="item.value" :label="item.name" :value="item.value"></el-option>-->
<!--        </el-select>-->
        <input type="text" placeholder="请选择日历" v-model="calendarStr" @focus="onFocus(1)" />
        <div class="calendarTip" v-if="calendarShow">
          <div class="calendarItem" v-for="(item,index) in calendarList" :key="index" :class="{ 'active': calendarIndex == item.value }"
               @click.stop="calendarItemClick(item)">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
<!--      <div class="birthday-item">-->
<!--        <el-select v-model="yearIndex" filterable placeholder="请选择年" clearable size="mini" style="width: 130px;" @change="changeYear(yearIndex)">-->
<!--          <el-option v-for="(item,index) in yearList" :key="index" :label="item.name" :value="item.value"></el-option>-->
<!--        </el-select>-->
<!--      </div>-->
<!--      <div class="birthday-item-text">-->
<!--        <span>年</span>-->
<!--      </div>-->
<!--      <div class="birthday-item">-->
<!--        <el-select v-model="monthIndex" filterable placeholder="请选择月" clearable size="mini" style="width: 130px;" @change="changeMonth(monthIndex)">-->
<!--          <el-option v-for="item in monthList" :key="item.value" :label="item.name" :value="item.value"></el-option>-->
<!--        </el-select>-->
<!--      </div>-->
<!--      <div class="birthday-item-text">-->
<!--        <span>月</span>-->
<!--      </div>-->
<!--      <div class="birthday-item">-->
<!--        <el-select v-model="dayIndex" filterable placeholder="请选择日" clearable size="mini" style="width: 130px;">-->
<!--          <el-option v-for="item in dayList" :key="item.value" :label="item.name" :value="item.value"></el-option>-->
<!--        </el-select>-->
<!--      </div>-->
<!--      <div class="birthday-item-text">-->
<!--        <span>日</span>-->
<!--      </div>-->
      <div class="birthday-item-input">
        <input type="text" placeholder="请输入生日" v-model="inputBirthday" @input="formatBirthdayInput" @focus="onFocus(2)" @blur="onBlur(2)"
               maxlength="8"/>
<!--        <div class="inputTip" v-if="inputFocus">-->
<!--          <span>输入格式：19970112</span>-->
<!--        </div>-->
      </div>
      <div class="birthday-item-start" @click="getList">
        <div class="iconImg">
          <img src="../../assets/img/icon_8.png" style="width: 100%;height: 100%"/>
        </div>
        <div class="iconText">
          <span>预测</span>
        </div>
      </div>
    </div>
    <div class="loadingBox" v-if="loadingShow">
      <div class="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div style="font-size: 14px;color: #ffffff;margin-top: 10px;">
        <span>加载中...</span>
      </div>
    </div>
    <div v-if="messageShow" class="messageBox animate__animated" :class="[messageCode === 200 ? 'success' : 'error',
           messageShow ? 'animate__bounceInDown' : 'animate__bounceOutUp']">
      <i :class="[messageCode == 200 ? 'el-icon-success' : 'el-icon-error' ,
      messageCode === 200 ? 'success' : 'error']" style="font-size: 14px;"></i> <span style="font-size: 14px;margin-left: 7px">{{ messageStr }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "loading.css";

.birthday-page {
  margin-top: 0.4rem  /* 40/100 */;
  width: 100%;
  height: 0.4rem  /* 40/100 */;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .birthday-item {
    width: 1.3rem  /* 130/100 */;
    //height: 0.30rem  /* 30/100 */;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 0.05rem  /* 5/100 */;
    opacity: 1;
    margin-left: 0.16rem  /* 16/100 */;

    &:first-child {
      width: 1.5rem  /* 150/100 */;
      margin-left: 0;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
      opacity: 1;
      font-weight: 400;
      font-size: 0.28rem  /* 28/100 */;
      color: #FE0000;
    }

    &:nth-child(2) {
      margin-left: 0.28rem  /* 28/100 */;
      position: relative;

      input{
        width: 1.3rem  /* 130/100 */;
        height: 0.36rem  /* 36/100 */;
        border: none;
        padding: 0 0.15rem  /* 15/100 */;
        outline: none;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 0.05rem  /* 5/100 */;
        font-weight: 400;
        font-size: 0.2rem  /* 20/100 */;
        color: #FE0000;
        line-height: 0.32rem  /* 32/100 */;
        text-align: center;
      }

      .calendarTip{
        position: absolute;
        top: 0.42rem  /* 42/100 */;
        left: 0;
        width: 1.3rem  /* 130/100 */;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 0.05rem  /* 5/100 */;
        font-weight: 400;
        font-size: 0.20rem  /* 20/100 */;
        color: #000000;
        line-height: 0.32rem  /* 32/100 */;
        padding: 0.1rem  /* 10/100 */ 0;
        box-sizing: border-box;

        &::after {
          content: '';
          position: absolute;
          top: -0.04rem  /* 8/100 */;
          left: 0.15rem  /* 15/100 */;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0.08rem 0.08rem 0 0; /* 调整四个边宽度以形成三角形 */
          border-color: rgba(255, 255, 255, 1) transparent transparent transparent; /* 上下左右颜色顺序决定三角形方向 */
          transform: rotate(45deg);
        }

        .calendarItem{
          width: 1.3rem  /* 130/100 */;
          height: 0.36rem  /* 36/100 */;
          padding: 0 0.15rem  /* 15/100 */;

          &:hover{
            background-color: #fee5b6;
          }
        }

        .active{
          background-color: #fee5b6;
        }
      }

    }

    .leftText {
      font-weight: 400;
      font-size: 0.2rem  /* 20/100 */;
      color: #FEE5B6;
    }

    .rightIcon {
      width: 0.18rem  /* 18/100 */;
      height: 0.1rem  /* 10/100 */;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .birthday-item-text{
    width: 0.4rem  /* 40/100 */;
    height: 0.4rem  /* 40/100 */;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.16rem  /* 16/100 */;
    font-weight: 400;
    font-size: 0.24rem  /* 24/100 */;
    color: #FE0000;
  }

  .birthday-item-input{
    width: 3rem  /* 300/100 */;
    height: 0.36rem  /* 36/100 */;
    margin-left: 0.28rem  /* 28/100 */;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    input{
      width: 3rem  /* 300/100 */;
      height: 0.36rem  /* 36/100 */;
      border: none;
      padding: 0 0.15rem  /* 15/100 */;
      outline: none;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 0.05rem  /* 5/100 */;
      font-weight: 400;
      font-size: 0.2rem  /* 20/100 */;
      color: #FE0000;
      line-height: 0.32rem  /* 32/100 */;
      //text-align: center;
    }

    .inputTip{
      position: absolute;
      top: 0.38rem  /* 38/100 */;
      left: 0;
      width: 3rem  /* 300/100 */;
      height: 0.36rem  /* 36/100 */;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 0.05rem  /* 5/100 */;
      font-weight: 400;
      font-size: 0.20rem  /* 20/100 */;
      color: #372b5c;
      line-height: 0.32rem  /* 32/100 */;
      padding: 0 0.2rem  /* 20/100 */;
      box-sizing: border-box;

      &::after {
        content: '';
        position: absolute;
        top: -0.04rem  /* 8/100 */;
        left: 0.15rem  /* 15/100 */;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.08rem 0.08rem 0 0; /* 调整四个边宽度以形成三角形 */
        border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent; /* 上下左右颜色顺序决定三角形方向 */
        transform: rotate(45deg);
      }
    }
  }

  .birthday-item-start{
    width: 1.1rem  /* 110/100 */;
    height: 0.36rem  /* 36/100 */;
    border-radius: 0.05rem  /* 5/100 */;
    opacity: 1;
    margin-left: 0.20rem  /* 24/100 */;
    background-color: #FF3434;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;

    .iconImg {
      width: 0.24rem  /* 24/100 */;
      height: 0.28rem  /* 28/100 */;
      margin-right: 0.08rem  /* 8/100 */;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .iconText{
      font-weight: 500;
      font-size: 0.24rem  /* 24/100 */;
      color: #FEE5B6;
    }
  }
}

.loadingBox{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.messageBox{
  width: 380px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  position: fixed;
  top: 100px; /* 更改为固定距离顶部100px */
  left: 50%;
  margin-left: -190px;
  z-index: 9999;
  background-color: #FE0000;
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.success{
  color: #67c23a;
  background-color: #f0f9eb;
}
.error{
  color: #f56c6c;
  background-color: #fef0f0;
}

</style>
