<script>
export default {
  name: "index",
  props: {
    fateData: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    fateData: {
      handler(newVal) {
        this.reset();
        if (newVal && Object.keys(newVal).length !== 0) {
          let self = this;
          self.listData = Object.assign({},newVal)
          self.show = true;
          self.mainList = newVal.lifeAnalysis;
          self.deputyList = newVal.animalsWord;
          newVal.animalsType.forEach((item,index)=>{
            self.monthList[index].text = Number(item)
          })


          // setTimeout(()=>{
          //   self.$nextTick(() => {
          //     document.querySelector("#scroll").scrollIntoView(true);
          //   });
          // },500)
        }else {
          this.reset()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      show: false,
      listData:{},
      mainList: '',
      deputyList: '',
      monthList:[
        {name:'1',text:''},
        {name:'2',text:''},
        {name:'3',text:''},
        {name:'4',text:''},
        {name:'5',text:''},
        {name:'6',text:''},
        {name:'7',text:''},
        {name:'8',text:''},
        {name:'9',text:''},
        {name:'10',text:''},
        {name:'11',text:''},
        {name:'12',text:''}
      ],
      stepIndex: 1,
    }
  },
  methods: {
    reset(){
      this.mainList = '';
      this.deputyList = '';
      this.show = false;
      this.stepIndex = 1;
    },
    previousStep(){
      this.stepIndex = 1;
    },
    nextStep(){
      this.stepIndex = 2;
    },
    nextUser(){
      this.reset();
      this.$emit('step',true)
    }
  }
}
</script>

<template>
  <div class="fate-page" v-if="show" id="scroll" ref="scroll">
    <div class="mainContent">
      <div class="content-top" v-if="stepIndex === 1">
        <div class="content-top-title">
          <span>您是怎样的人</span>
        </div>
        <div class="content-top-content">
          <span v-html="mainList"></span>
        </div>
        <div class="content-top-btn" @click="nextStep">
          <span>查看{{ new Date().getFullYear() }}年的您</span>
        </div>
      </div>
      <div class="content-bottom" v-if="stepIndex === 2">
        <div class="content-bottom-title">
          <span>{{ new Date().getFullYear() }}年运势</span>
        </div>
        <div class="content-bottom-birthday">
          <span v-html="deputyList"></span>
        </div>
        <div class="content-bottom-month">
          <div class="month-item" v-for="(item,index) in monthList" :key="index">
            <span class="month-text">{{ item.text == 1 ? '凶' : item.text == 2 ? '平' : '吉' }}</span>
            <span class="month-monthTitle">{{ item.name }}月</span>
          </div>
        </div>
        <div class="btnBox">
          <div class="content-bottom-btn" @click="previousStep">
            <span>上一页</span>
          </div>
          <div class="content-bottom-btn" @click="nextUser">
            <span>下一位</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@font-face {
  font-family: AlimamaDaoLiTi;
  src: url('../../assets/font/AlimamaDaoLiTi.ttf');
}

.fate-page {
  //border-top: 1px solid #FEE5B6;
  //margin-top: 0.88rem  /* 88/100 */;
  padding: 0 0 0.6rem  /* 60/100 */;
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    width: 100%;
    height: 0.45rem  /* 45/100 */;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: AlimamaDaoLiTi;
  }

  .mainContent {
    //margin-top: 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content-top{
      width: 10rem  /* 1000/100 */;
      display: flex;
      flex-direction: column;
      align-items: center;

      .content-top-title{
        width: 100%;
        font-weight: 400;
        font-size: 0.48rem  /* 48/100 */;
        color: #FF3434;
        text-align: center;
        font-family: AlimamaDaoLiTi;
      }

      .content-top-content{
        width: 100%;
        height: 100%;
        margin-top: 0.25rem  /* 25/100 */;
        padding: 0.3rem  /* 30/100 */;
        box-sizing: border-box;
        background-color: #FEE5B6;
        border: 0.02rem  /* 2/100 */ solid #DF752D;
        font-weight: 300;
        font-size: 0.2rem  /* 20/100 */;
        color: #3B2C0F;
        line-height: 0.36rem  /* 36/100 */;
      }

      .content-top-btn{
        margin-top: 0.55rem  /* 55/100 */;
        width: 3.72rem  /* 372/100 */;
        height: 0.75rem  /* 75/100 */;
        background-color: #793300;
        border-radius: 0.1rem  /* 10/100 */;
        color: #FEE5B6;
        font-size: 0.34rem  /* 34/100 */;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }
    .content-bottom{
      width: 10rem  /* 1000/100 */;
      display: flex;
      flex-direction: column;
      align-items: center;

      .content-bottom-title{
        font-weight: 400;
        font-size: 0.48rem  /* 48/100 */;
        color: #FF3434;
        text-align: center;
        font-family: AlimamaDaoLiTi;
      }

      .content-bottom-birthday{
        margin-top: 0.25rem  /* 25/100 */;
        width: 100%;
        padding: 0.3rem  /* 30/100 */;
        box-sizing: border-box;
        background-color: #FEE5B6;
        border: 0.02rem  /* 2/100 */ solid #DF752D;
        font-weight: 300;
        font-size: 0.2rem  /* 20/100 */;
        color: #3B2C0F;
        line-height: 0.36rem  /* 36/100 */;
      }
      .content-bottom-month{
        margin-top: 0.25rem  /* 25/100 */;
        width: 100%;
        height: 2.8rem  /* 280/100 */;
        box-sizing: border-box;
        background-color: #FEE5B6;
        border: 0.02rem  /* 2/100 */ solid #DF752D;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .month-item{
          width: 16.65%;
          height: 1.4rem  /* 140/100 */;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          font-family: AlimamaDaoLiTi;

          &:nth-child(1),&:nth-child(7){
            border-bottom: 1px dashed #D8B181;
          }

          &:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(6){
            border-left: 1px dashed #D8B181;
            border-bottom: 1px dashed #D8B181;
          }
          &:nth-child(8),&:nth-child(9),&:nth-child(10),&:nth-child(11),&:nth-child(12){
            border-left: 1px dashed #D8B181;
          }


          .month-name{
            font-weight: bold;
            font-size: 0.4rem  /* 40/100 */;
            color: #7F3808;
            z-index: 9;
          }
          .month-text{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: 0.88rem  /* 88/100 */;
            color: #f86d25;
          }
          .month-monthTitle{
            width: 100%;
            height: 0.4rem  /* 40/100 */;
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: 0.3rem  /* 30/100 */;
            line-height: 0.4rem  /* 40/100 */;
            text-align: center;
            color: #7F3808;
          }
        }
      }

      .btnBox{
        margin-top: 0.55rem  /* 55/100 */;
        width: 100%;
        display: flex;
        justify-content: space-around;
        .content-bottom-btn{
          width: 3.72rem  /* 372/100 */;
          height: 0.75rem  /* 75/100 */;
          background-color: #793300;
          border-radius: 0.1rem  /* 10/100 */;
          color: #FEE5B6;
          font-size: 0.34rem  /* 34/100 */;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }


    }
  }

}
</style>
