<script>
// 画圈图片
import roundNum1  from "../../assets/img/round1.png";
import roundNum2  from "../../assets/img/round2.png";
import roundNum3  from "../../assets/img/round3.png";
import roundNum4  from "../../assets/img/round4.png";
import roundNum5  from "../../assets/img/round5.png";
import roundNum6  from "../../assets/img/round6.png";

import * as url from "url";

export default {
  name: "index",
  computed: {
    url() {
      return url
    }
  },
  props:{
    resultData: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    resultData: {
      handler(newVal) {
        this.reset()
        if (newVal && Object.keys(newVal).length !== 0) {
          let self = this;
          self.list = Object.assign({},newVal)

          if(self.list.master.length > 0){
            let master = self.list.master;
            master.forEach((item)=>{
              // 主线(获取x轴)
              if(item == '1-2-3'){
                self.xLine123 = true
              }
              if(item == '4-5-6'){
                self.xLine456 = true
              }
              if(item == '7-8-9'){
                self.xLine789 = true
              }
              // 主线(获取y轴)
              if(item == '1-4-7'){
                self.yLine147 = true
              }
              if(item == '2-5-8'){
                self.yLine258 = true
              }
              if(item == '3-6-9'){
                self.yLine369 = true
              }
              // 主线(获取斜线)
              if(item == '1-5-9'){
                self.obliqueLine159 = true
              }
              if(item == '3-5-7'){
                self.obliqueLine357 = true
              }
            })
          }

          // 副线
          const slave = self.list.slave;
          if(slave.length > 0){
            slave.forEach(( item ) => {
              if(item == '2-4'){
                self.deputyLine24 = true;
              }
              if(item == '4-8'){
                self.deputyLine48 = true;
              }
              if(item == '2-6'){
                self.deputyLine26 = true;
              }
              if(item == '6-8'){
                self.deputyLine68 = true;
              }
            });
          }
          // 画圈
          const array = Object.entries(self.list.round);
          if(array.length > 0){
            array.forEach(( item,index ) => {
              self.numList[index].round = item[1];
            });
          }

          const result = self.list.master.flat().join(',');
          const arr = result.split(',');
          const mergedArr = arr.flatMap(entry => entry.split('-'));
          const uniqueSet = new Set(mergedArr);
          const uniqueArr = [...uniqueSet];
          console.log("主线打点",uniqueArr)

          // 主线打点
          if(uniqueArr.length > 0){
            self.mainNumList.forEach((x,xIndex)=>{
              uniqueArr.forEach(( y ) => {
                if(x.index == y){
                  self.mainNumList[xIndex].show = true;
                }
              });
            })
          }

          const result2 = self.list.slave.flat().join(',');
          const arr2 = result2.split(',');
          const mergedArr2 = arr2.flatMap(entry => entry.split('-'));
          const uniqueSet2 = new Set(mergedArr2);
          const uniqueArr2 = [...uniqueSet2];
          console.log("副线打点",uniqueArr2)

          // 副线打点
          if(uniqueArr2.length > 0){
            self.deputyNumList.forEach((x,xIndex)=>{
              uniqueArr2.forEach(( y ) => {
                if(x.index == y){
                  self.deputyNumList[xIndex].show = true;
                }
              });
            })
          }
        }else{
          this.reset()
        }
      },
      immediate:true
    }
  },
  data(){
    return{
      list:{},
      // x
      xLine123: false,
      xLine456: false,
      xLine789: false,
      // y
      yLine147: false,
      yLine258: false,
      yLine369: false,
      // 斜线
      obliqueLine159: false,
      obliqueLine357: false,
      // 副线
      deputyLine24: false,
      deputyLine48: false,
      deputyLine26: false,
      deputyLine68: false,
      // 画圈
      numList:[
        {index:1,round:''},
        {index:2,round:''},
        {index:3,round:''},
        {index:4,round:''},
        {index:5,round:''},
        {index:6,round:''},
        {index:7,round:''},
        {index:8,round:''},
        {index:9,round:''},
      ],
      localImages:[
        roundNum1,
        roundNum2,
        roundNum3,
        roundNum4,
        roundNum5,
        roundNum6,
      ],
      // 主
      mainNumList: [
        {index: 1, show: ''},
        {index: 2, show: ''},
        {index: 3, show: ''},
        {index: 4, show: ''},
        {index: 5, show: ''},
        {index: 6, show: ''},
        {index: 7, show: ''},
        {index: 8, show: ''},
        {index: 9, show: ''},
      ],
      // 副
      deputyNumList: [
        {index: 1, show: ''},
        {index: 2, show: ''},
        {index: 3, show: ''},
        {index: 4, show: ''},
        {index: 5, show: ''},
        {index: 6, show: ''},
        {index: 7, show: ''},
        {index: 8, show: ''},
        {index: 9, show: ''},
      ],
    }
  },
  mounted(){

  },
  methods:{
    reset(){
      this.xLine123 = false;
      this.xLine456 = false;
      this.xLine789 = false;
      this.yLine147 = false;
      this.yLine258 = false;
      this.yLine369 = false;
      this.obliqueLine159 = false;
      this.obliqueLine357 = false;
      this.deputyLine24 = false;
      this.deputyLine48 = false;
      this.deputyLine26 = false;
      this.deputyLine68 = false;

      this.numList = [
        {index:1,round:''},
        {index:2,round:''},
        {index:3,round:''},
        {index:4,round:''},
        {index:5,round:''},
        {index:6,round:''},
        {index:7,round:''},
        {index:8,round:''},
        {index:9,round:''},
      ]
      this.mainNumList = [
        {index: 1, show: ''},
        {index: 2, show: ''},
        {index: 3, show: ''},
        {index: 4, show: ''},
        {index: 5, show: ''},
        {index: 6, show: ''},
        {index: 7, show: ''},
        {index: 8, show: ''},
        {index: 9, show: ''},
      ]
      this.deputyNumList = [
        {index: 1, show: ''},
        {index: 2, show: ''},
        {index: 3, show: ''},
        {index: 4, show: ''},
        {index: 5, show: ''},
        {index: 6, show: ''},
        {index: 7, show: ''},
        {index: 8, show: ''},
        {index: 9, show: ''},
      ]
    },
  }
}
</script>

<template>
  <div class="result-page">
    <div class="resultLeft animate__animated animate__bounceInLeft">
      <div class="resultLeft-item" v-for="(item) in numList" :key="item.index">
        <span>{{ item.index }}</span>
        <div v-show="item.round > 0" class="roundBox" :style="{ backgroundImage: `url(${localImages[item.round - 1]})` }"></div>
      </div>
      <!-- 主线-x -->
      <div class="resultLeft-item-line-x resultLeft-item-line-x123 gradient-x" v-if="xLine123"></div>
      <div class="resultLeft-item-line-x resultLeft-item-line-x456 gradient-x" v-if="xLine456"></div>
      <div class="resultLeft-item-line-x resultLeft-item-line-x789 gradient-x" v-if="xLine789"></div>
      <!-- 主线-y -->
      <div class="resultLeft-item-line-y resultLeft-item-line-y147 gradient-y" v-if="yLine147"></div>
      <div class="resultLeft-item-line-y resultLeft-item-line-y258 gradient-y" v-if="yLine258"></div>
      <div class="resultLeft-item-line-y resultLeft-item-line-y369 gradient-y" v-if="yLine369"></div>
      <!-- 主线-斜线 -->
      <div class="resultLeft-item-line-oblique resultLeft-item-line-oblique159 gradient-x" v-if="obliqueLine159"></div>
      <div class="resultLeft-item-line-oblique resultLeft-item-line-oblique357 gradient-x" v-if="obliqueLine357"></div>
      <!-- 副线 -->
      <div class="resultLeft-item-line-deputyLine24 gradient-x" v-if="deputyLine24"></div>
      <div class="resultLeft-item-line-deputyLine48 gradient-x" v-if="deputyLine48"></div>
      <div class="resultLeft-item-line-deputyLine26 gradient-x" v-if="deputyLine26"></div>
      <div class="resultLeft-item-line-deputyLine68 gradient-x" v-if="deputyLine68"></div>
    </div>
    <div class="resultRight animate__animated animate__bounceInRight">
      <div class="resultRight-top">
        <div class="shape">
          <div class="shapeLeft">
            <span>生命灵数：</span>
          </div>
          <div class="shapeRight">
            <span>{{ list.life ? list.life : '？' }}</span>
          </div>
        </div>
      </div>
      <div class="resultRight-bottom">
        <div class="resultRight-bottom-left">
          <div class="resultRight-bottom-left-top">
            <div class="resultRight-bottom-left-top-item" v-for="(item) in mainNumList" :key="item.index">
              <span>{{ item.index }}</span>
              <span class="spot" v-if="item.show"></span>
            </div>
            <!-- 画线 -->
            <!-- 主线-x -->
            <div class="resultLeft-item-line-x resultLeft-item-line-x123 background" v-if="xLine123"></div>
            <div class="resultLeft-item-line-x resultLeft-item-line-x456 background" v-if="xLine456"></div>
            <div class="resultLeft-item-line-x resultLeft-item-line-x789 background" v-if="xLine789"></div>
            <!-- 主线-y -->
            <div class="resultLeft-item-line-y resultLeft-item-line-y147 background" v-if="yLine147"></div>
            <div class="resultLeft-item-line-y resultLeft-item-line-y258 background" v-if="yLine258"></div>
            <div class="resultLeft-item-line-y resultLeft-item-line-y369 background" v-if="yLine369"></div>
            <!-- 主线-斜线 -->
            <div class="resultLeft-item-line-oblique resultLeft-item-line-oblique159 background" v-if="obliqueLine159"></div>
            <div class="resultLeft-item-line-oblique resultLeft-item-line-oblique357 background" v-if="obliqueLine357"></div>
          </div>
          <div class="resultRight-bottom-left-bottom">
            <span>主线</span>
          </div>
        </div>
        <div class="resultRight-bottom-right">
          <div class="resultRight-bottom-right-top">
            <div class="resultRight-bottom-right-top-item" v-for="(item) in deputyNumList" :key="item.index">
              <span>{{ item.index }}</span>
              <span class="spot" v-if="item.show"></span>
            </div>
            <!-- 画线 -->
            <div class="resultLeft-item-line-deputyLine24 background" v-if="deputyLine24"></div>
            <div class="resultLeft-item-line-deputyLine48 background" v-if="deputyLine48"></div>
            <div class="resultLeft-item-line-deputyLine26 background" v-if="deputyLine26"></div>
            <div class="resultLeft-item-line-deputyLine68 background" v-if="deputyLine68"></div>
          </div>
          <div class="resultRight-bottom-right-bottom">
            <span>副线</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@font-face {
  font-family: AlimamaDaoLiTi;
  src: url('../../assets/font/AlimamaDaoLiTi.ttf');
}
.result-page{
  margin-top: 0.4rem  /* 40/100 */;
  width: 100%;
  height: 4.98rem  /* 498/100 */;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .resultLeft{
    width: 4.98rem  /* 498/100 */;
    height: 4.98rem  /* 498/100 */;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.24rem  /* 24/100 */;
    position: relative;

    .resultLeft-item{
      width: 1.5rem  /* 150/100 */;
      height: 1.5rem  /* 150/100 */;
      box-sizing: border-box;
      background-color: #FEE5B6;
      border-radius: 0.1rem  /* 10/100 */;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 0.4rem  /* 40/100 */;
      color: #7F3808;
      position: relative;

      .roundBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.38rem  /* 138/100 */;
        height: 1.38rem  /* 138/100 */;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }


  }
  .resultRight{
    width: 4.76rem  /* 476/100 */;
    height: 4.98rem  /* 498/100 */;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .resultRight-top{
      width: 100%;
      height: 2.2rem  /* 220/100 */;
      background: url("../../assets/img/icon_5.png") center no-repeat;
      background-size:cover;

      .shape {
        width: 100%;
        height: 2.2rem  /* 220/100 */;
        display: flex;
        justify-content: center;
        align-items: center;
        .shapeLeft{
          width: 1.58rem  /* 158/100 */;
          height: 0.8rem  /* 80/100 */;
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 0.3rem  /* 30/100 */;
          color: #7F3808;
        }
        .shapeRight{
          margin-left: 0.3rem  /* 30/100 */;
          width: 0.28rem  /* 28/100 */;
          height: 0.8rem  /* 80/100 */;
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 0.6rem  /* 60/100 */;
          color: #FE0000;
          font-family: AlimamaDaoLiTi;
        }
      }
    }
    .resultRight-bottom{
      width: 100%;
      height: 2.54rem  /* 254/100 */;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .resultRight-bottom-left{
        width: 2.26rem  /* 226/100 */;
        height: 100%;
        display: flex;
        flex-direction: column;
        .resultRight-bottom-left-top{
          width: 2.26rem  /* 226/100 */;
          height: 2.26rem  /* 226/100 */;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          background-color: #fee5b6;
          position: relative;

          .resultRight-bottom-left-top-item{
            width: 0.75rem  /* 75/100 */;
            height: 0.75rem  /* 75/100 */;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            font-weight: 500;
            font-size: 0.24rem  /* 24/100 */;
            color: #B27428;

            .spot{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 0.08rem  /* 8/100 */;
              height: 0.08rem  /* 8/100 */;
              background-color: #FE0000;
              border-radius: 4px;
            }

            &:nth-child(1),&:nth-child(2),&:nth-child(3){
              border-bottom: 0.01rem  /* 1/100 */ solid #D8B181;
            }

            &:nth-child(4),&:nth-child(5),&:nth-child(6){
              border-bottom: 0.01rem  /* 1/100 */ solid #D8B181;
            }

            &:nth-child(2),&:nth-child(5),&:nth-child(8){
              border-left: 0.01rem  /* 1/100 */ solid #D8B181;
            }

            &:nth-child(3),&:nth-child(6),&:nth-child(9){
              border-left: 0.01rem  /* 1/100 */ solid #D8B181;
            }

          }

        }

        .resultRight-bottom-left-bottom{
          width: 2.26rem  /* 226/100 */;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 0.22rem  /* 22/100 */;
          color: #FFF5C8;
        }

      }
      .resultRight-bottom-right{
        width: 2.26rem  /* 226/100 */;
        height: 100%;
        display: flex;
        flex-direction: column;
        .resultRight-bottom-right-top{
          width: 2.26rem  /* 226/100 */;
          height: 2.26rem  /* 226/100 */;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          background-color: #fee5b6;
          position: relative;

          .resultRight-bottom-right-top-item{
            width: 0.75rem  /* 75/100 */;
            height: 0.75rem  /* 75/100 */;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            font-weight: 500;
            font-size: 0.24rem  /* 24/100 */;
            color: #B27428;

            .spot{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 0.08rem  /* 8/100 */;
              height: 0.08rem  /* 8/100 */;
              background-color: #FE0000;
              border-radius: 4px;
            }

            &:nth-child(1),&:nth-child(2),&:nth-child(3){
              border-bottom: 0.01rem  /* 1/100 */ solid #D8B181;
            }

            &:nth-child(4),&:nth-child(5),&:nth-child(6){
              border-bottom: 0.01rem  /* 1/100 */ solid #D8B181;
            }

            &:nth-child(2),&:nth-child(5),&:nth-child(8){
              border-left: 0.01rem  /* 1/100 */ solid #D8B181;
            }

            &:nth-child(3),&:nth-child(6),&:nth-child(9){
              border-left: 0.01rem  /* 1/100 */ solid #D8B181;
            }
          }

        }
        .resultRight-bottom-right-bottom{
          width: 2.26rem  /* 226/100 */;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 0.22rem  /* 22/100 */;
          color: #FFF5C8;
        }

      }
    }
  }

  .background{
    background-color: #ff0000;
  }

  .gradient-x{
    --gradient-from-transparent-to-red: linear-gradient(to left, transparent 0%, transparent 50%, #FE0000 50%, #FE0000 100%);
    background-image: var(--gradient-from-transparent-to-red);
    background-size: 0.15rem  /* 15/100 */ 0.03rem  /* 3/100 */;
    background-repeat: repeat-x;
  }

  .gradient-y{
    --gradient-from-transparent-to-red: linear-gradient(to top, transparent 0%, transparent 50%, #FE0000 50%, #FE0000 100%);
    background-image: var(--gradient-from-transparent-to-red);
    background-size: 0.03rem  /* 3/100 */ 0.15rem  /* 15/100 */;
    background-repeat: repeat-y;
  }

  .resultLeft-item-line-x{
    width: 75%;
    height: 0.03rem  /* 3/100 */;
    transform: translate(-50%, -50%);
  }

  .resultLeft-item-line-x123{
    position: absolute;
    top: 15%;
    left: 50%;
  }
  .resultLeft-item-line-x456{
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .resultLeft-item-line-x789{
    position: absolute;
    top: 85%;
    left: 50%;
  }

  .resultLeft-item-line-y{
    width: 0.03rem  /* 3/100 */;
    height: 75%;
    transform: translate(-50%, -50%);
  }

  .resultLeft-item-line-y147{
    position: absolute;
    top: 50%;
    left: 15%;
  }
  .resultLeft-item-line-y258{
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .resultLeft-item-line-y369{
    position: absolute;
    top: 50%;
    left: 85%;
  }

  .resultLeft-item-line-oblique{
    width: 100%;
    height: 0.03rem  /* 3/100 */;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
  }

  .resultLeft-item-line-oblique159{
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .resultLeft-item-line-oblique357{
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .resultLeft-item-line-deputyLine24{
    width: 51%;
    height: 0.03rem  /* 3/100 */;
    position: absolute;
    top: 33%;
    left: 32%;
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(135deg);
  }
  .resultLeft-item-line-deputyLine48{
    width: 51%;
    height: 0.03rem  /* 3/100 */;
    position: absolute;
    top: 68%;
    left: 33%;
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .resultLeft-item-line-deputyLine26{
    width: 51%;
    height: 0.03rem  /* 3/100 */;
    position: absolute;
    top: 33%;
    left: 68%;
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .resultLeft-item-line-deputyLine68{
    width: 51%;
    height: 0.03rem  /* 3/100 */;
    position: absolute;
    top: 68%;
    left: 67%;
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(135deg);
  }
}
</style>
